import api from "./api-service";
import { stringify } from "querystring";


export const findAll = (filter) => {
  return api().get(`complementar-therapy?${stringify(filter)}`);
};

export const save = (body) => {
  return api().post(`complementar-therapy`, body);
};
